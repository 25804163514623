


























































































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { TabLayout } from "@/layouts";
import { isEmpty } from "lodash";
import Vuelidate from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

import { OrgDetails } from "@/types";
import { CustomInput, Loader, Dropdown } from "@/components";
import { formatPhoneNumberUtil } from "@/utils/common";

const orgModule = namespace("org");
const userModule = namespace("user");
const configModule = namespace("config");
Vue.use(Vuelidate);

@Component({
  components: {
    TabLayout,
    CustomInput,
    Loader,
    Dropdown,
  },
  validations: {
    org: {
      name: {
        required,
      },
      email: {
        email,
      },
      pic: {
        email: {
          email,
        },
      },
    },
  },
})
export default class AccountOrganization extends Vue {
  @orgModule.Getter("orgDetails") orgDetails!: OrgDetails;
  @configModule.Getter("cities") cities!: Array<any>;
  @configModule.Getter("provinces") provinces!: Array<any>;
  @orgModule.Action("updateOrgInfo") updateOrgInfo!: (
    payload: OrgDetails
  ) => void;
  @userModule.Getter("isAdmin") isAdmin!: boolean;

  loading = false;
  org: OrgDetails = {};

  get saveButtonText(): string {
    return this.loading ? "Saving Org Info" : "Save";
  }

  get getCities(): Array<any> {
    // return ["Manila", "Quezon"];
    if (!this.provinces.length) {
      return [];
    }

    const province = this.provinces.find((province) => {
      return province.name === this.org.address?.province;
    });

    if (!province) {
      return [];
    }

    const city = this.cities.find((city) => {
      return province.id === city.provinceId;
    });

    const list = city.list.map((city: any) => city.name);

    return list;
  }

  get getProvinces(): Array<any> {
    if (!this.provinces.length) {
      return [];
    }

    return this.provinces.map((province) => {
      return province.name;
    });
  }

  get cityPlaceholder() {
    if (this.org.address?.city) {
      return "";
    } else if (!this.org.address!.province) {
      return "Please select a province first";
    } else {
      return "";
    }
  }

  keyupOrgPhoneNumber() {
    this.org.phoneNumber = formatPhoneNumberUtil(
      this.org.phoneNumber as string
    );
  }

  keyupPICPhoneNumber() {
    const pic = this.org.pic as Record<string, any>;
    pic.phoneNumber = formatPhoneNumberUtil(
      this.org?.pic?.phoneNumber as string
    ) as string;
  }

  async onSubmit(): Promise<void> {
    this.$v.$touch();
    if (this.$v.$invalid) return;
    this.loading = true;

    try {
      const payload = this._constructOrgInfo(this.org);
      await this.updateOrgInfo(payload);
    } catch (error) {
      const err = error as Error;
      console.log(err.message);
    } finally {
      this.loading = false;
    }
  }

  _constructOrgInfo(data: OrgDetails): OrgDetails {
    return {
      id: data.id,
      name: data.name,
      corporateID: data.corporateID,
      industry: data.industry,
      address: {
        line1: data.address?.line1,
        line2: data.address?.line2,
        province: data.address?.province,
        city: data.address?.city,
        district: data.address?.district,
        zipCode: data.address?.zipCode,
      },
      phoneNumber: data.phoneNumber,
      email: data.email,
      pic: {
        name: data.pic?.name,
        email: data.pic?.email,
        phoneNumber: data.pic?.phoneNumber,
      },
    };
  }

  @Watch("orgDetails", { immediate: true })
  watchRows(orgDetails: OrgDetails) {
    if (!isEmpty(orgDetails)) {
      this.org = this._constructOrgInfo(orgDetails);
    }
  }

  resetUserInfo() {
    this.org = this._constructOrgInfo(this.orgDetails);
  }

  // mounted() {
  //   this.getOrgDetails();
  // }
}
